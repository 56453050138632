import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Grundfos from "../../assets/images/our-work/grundfos1.png";
import Lowenfels from "../../assets/images/our-work/lowenfels1.png";
import SloveniaControl from "../../assets/images/our-work/slovenia-control1.png";
import Savacoop from "../../assets/images/our-work/savacoop1.png";
import Scayla from "../../assets/images/our-work/scayla1.png";
import Block7 from "../../assets/images/our-work/block71.png";
import Qualipet from "../../assets/images/our-work/qualipet1.png";
import Celestyal from "../../assets/images/our-work/celestyal1.png";
import Leifheit from "../../assets/images/our-work/leifheit1.png";
import Bytetobite from "../../assets/images/our-work/bytetobite1.png";
import Refusion from "../../assets/images/our-work/refusion1.png";
import Immotron from "../../assets/images/our-work/immotron1.png";
import Roadx from "../../assets/images/our-work/roadx1.png";
import Lehner from "../../assets/images/our-work/lehner1.png";
import Darex from "../../assets/images/our-work/darex1.png";
import LesAmbassadeurs from "../../assets/images/our-work/les-ambassadeurs.png";
import Motobike from "../../assets/images/our-work/motobike1.png";
import Nahrin from "../../assets/images/our-work/nahrin1.png";
import Amavita from "../../assets/images/our-work/amavita1.png";
import Huntessa from "../../assets/images/our-work/huntessa.png";
import Inflow from "../../assets/images/our-work/inflow1.png";
import Gutmann from "../../assets/images/our-work/gutmann1.png";
import Pharma from "../../assets/images/our-work/pharma1.png";
import AlexisMuller from "../../assets/images/our-work/alexis-muller1.png";
import AsiaWaterJet from "../../assets/images/our-work/asia-waterjet.png";
import FruskogorskiMaraton from "../../assets/images/our-work/fruskogorski-maraton1.png";
import FileSvg from "../../assets/images/our-work/file.svg";
import Grossoptic from "../../assets/images/our-work/grossoptic1.png";
import UnisunInvest from "../../assets/images/our-work/unisun1.png";
import NavPortugal from "../../assets/images/our-work/nav-portugal.png";
import Eurocontrol from "../../assets/images/our-work/eurocontrol.png";

const Companies = () => {
  const { t } = useTranslation();

  const ourWorkContent = [
    {
      image: Eurocontrol,
      technologies: ["Mobile development", "Web development", "UI/UX"],
      website: "https://eurocontrol.int/",
      largerImage: true,
    },
    {
      image: Grundfos,
      caseStudyUrl: "/case-studies/quality-control",
      technologies: ["Application development", "Hardware integration"],
    },
    {
      image: Lowenfels,
      technologies: ["Web development", "UI/UX", "Graphic design"],
      website: "https://www.loewenfels.ch/",
    },
    {
      image: SloveniaControl,
      caseStudyUrl: "/case-studies/e-aro",
      technologies: ["Web development", "UI/UX"],
    },
    {
      image: NavPortugal,
      technologies: ["Web development", "UI/UX"],
      website: "https://fplbriefing.nav.pt/login",
    },
    {
      image: Savacoop,
      technologies: [
        "ERP",
        "eCommerce",
        "Web development",
        "UI/UX",
        "Graphic design",
      ],
      website: "https://savacoop.rs/",
    },
    {
      image: Scayla,
      technologies: ["Web development", "UI/UX"],
      website: "https://scayla.com/",
    },
    {
      image: Block7,
      technologies: ["Web development", "UI/UX"],
      website: "https://block7.ae/",
    },
    {
      image: Qualipet,
      website: "https://qualipet.ch",
    },
    {
      image: Celestyal,
      technologies: ["Web development"],
      website: "https://celestyal.com",
    },
    {
      image: Refusion,
      technologies: ["Web development", "UI/UX"],
      website: "https://www.refusion.com",
    },
    {
      image: Immotron,
      technologies: ["Web development", "UI/UX"],
      website: "https://immotron.com/",
    },
    {
      image: Roadx,
      technologies: ["Web development", "UI/UX"],
      website: "https://roadx.app/",
    },
    {
      image: Leifheit,
      technologies: ["eCommerce"],
      website: "https://leifheit.rs/",
    },
    {
      image: Lehner,
      website: "https://www.lehner-versand.ch/",
    },
    {
      image: Darex,
      technologies: ["eCommerce"],
      website: "https://darex.rs/",
    },
    {
      image: Bytetobite,
      technologies: ["eCommerce", "Web development", "UI/UX", "Social media"],
      website: "https://bytetobite.industries/",
    },
    {
      image: Motobike,
      technologies: ["eCommerce"],
      website: "https://motobikeshop.rs/",
    },
    {
      image: Nahrin,
      website: "https://www.nahrin.ch/",
    },
    {
      image: LesAmbassadeurs,
      website: "https://www.lesambassadeurs.ch/",
    },
    {
      image: Amavita,
      website: "https://www.amavita.ch/",
    },
    {
      image: Huntessa,
      technologies: ["eCommerce"],
      website: "https://huntessa.com/",
    },
    {
      image: Inflow,
      technologies: ["Web development", "UI/UX"],
      website: "https://inflow-business.cz/",
    },
    {
      image: Gutmann,
      technologies: ["Web development", "UI/UX"],
      website: "https://gutmann-na.com/",
    },
    {
      image: Pharma,
      technologies: ["Web development", "UI/UX"],
      website: "https://pharma.com.sa/",
    },
    {
      image: AlexisMuller,
      technologies: ["Web development", "UI/UX"],
      website: "https://www.alexis-muller.ch/",
    },
    {
      image: AsiaWaterJet,
      technologies: ["Web development", "UI/UX"],
      website: "https://asiawaterjet.com/",
    },
    {
      image: FruskogorskiMaraton,
      technologies: ["Web development", "UI/UX"],
      website: "https://www.fruskogorski-maraton.com/",
    },
    {
      image: UnisunInvest,
      technologies: ["Web development", "UI/UX"],
      website: "https://www.unisun-invest.ch/",
    },
    {
      image: Grossoptic,
      technologies: ["Web development", "UI/UX"],
      website: "https://grossoptic.com/",
    },
  ];

  return (
    <section className="m-companies">
      <div className="_wr">
        <div className="_w m-companies__top">
          <div className="m-companies__top">
            <h2 className="m-companies__top--title">
              {t("ourWork.companies.headline")}
            </h2>
            <span className="a-line -red"></span>
          </div>
        </div>

        <div className="_w">
          {ourWorkContent.map(
            (
              { image, name, caseStudyUrl, technologies, website, largerImage },
              i
            ) => {
              return (
                <a
                  key={i}
                  className="_m6 _xl4 m-companies__item"
                  href={caseStudyUrl ? caseStudyUrl : website}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`m-companies__item--content ${
                      caseStudyUrl || website ? "-hasUrl" : ""
                    }  ${website ? "-website" : ""}`}
                  >
                    <img
                      className={`m-companies__item--logo ${
                        largerImage ? "-largerImage" : ""
                      }`}
                      src={image}
                      alt=""
                    />
                    {name && (
                      <div className="m-companies__item--text">
                        <span className="m-companies__item--name">{name}</span>
                      </div>
                    )}
                    {technologies && (
                      <div className="m-companies__item--technologies">
                        {technologies.map((technology, i) => (
                          <span
                            className="m-companies__item--technology"
                            key={i}
                          >
                            {technology}
                          </span>
                        ))}
                      </div>
                    )}
                    {caseStudyUrl && (
                      <>
                        <FileSvg> </FileSvg>
                        <span
                          to={caseStudyUrl}
                          className="a-btn -tertiary -work"
                        >
                          {t("buttons.discoverCaseStudy")}{" "}
                          <span className="a-arrow -long"></span>
                        </span>
                      </>
                    )}
                    {website && (
                      <span className="a-btn -tertiary -work">
                        {t("buttons.seeTheirWebsite")}{" "}
                        <span className="a-arrow -long"></span>
                      </span>
                    )}
                  </div>
                </a>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default Companies;
